import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
}
export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">


<path d="M5175 8274 c-473 -37 -968 -209 -1370 -477 -79 -52 -191 -132 -205
-147 -3 -3 -34 -30 -70 -60 -199 -167 -395 -391 -553 -632 -508 -775 -605
-1774 -257 -2644 49 -122 142 -316 184 -383 110 -173 200 -306 226 -331 3 -3
35 -41 71 -85 211 -255 553 -529 859 -686 450 -233 848 -329 1357 -329 350 0
601 40 913 147 709 243 1287 734 1637 1393 212 398 333 887 333 1345 0 857
-381 1673 -1035 2217 -148 123 -135 113 -225 173 -41 28 -76 53 -78 58 -2 4
-7 7 -12 7 -4 0 -37 18 -72 39 -299 181 -688 318 -1068 376 -101 16 -520 28
-635 19z m441 -104 c263 -21 581 -89 749 -160 11 -4 47 -18 80 -31 310 -116
671 -354 925 -609 193 -193 352 -399 464 -603 14 -26 33 -58 40 -70 25 -37
106 -225 157 -363 300 -815 188 -1758 -295 -2479 -145 -217 -191 -272 -375
-455 -231 -228 -426 -368 -711 -510 -251 -125 -495 -204 -798 -257 -147 -25
-175 -27 -447 -27 -323 0 -412 10 -687 79 -403 101 -811 311 -1119 575 -109
93 -280 270 -368 380 -607 759 -779 1778 -453 2685 100 279 210 485 385 725
87 119 112 147 256 294 229 233 459 400 756 546 99 49 200 95 225 104 25 8 79
27 120 41 314 111 738 163 1096 135z"/>
<path d="M4083 7296 c-94 -30 -168 -99 -202 -191 l-21 -57 2 -1206 c3 -1140 4
-1209 21 -1246 27 -58 104 -134 166 -164 l56 -27 1250 -3 c932 -2 1264 0 1305
9 74 15 127 44 176 93 50 51 73 100 85 179 12 89 11 2286 -1 2365 -17 101 -81
185 -180 234 l-45 23 -1285 2 c-1038 1 -1293 -1 -1327 -11z m2618 -67 c56 -26
110 -79 135 -134 l24 -50 0 -1181 c0 -1320 5 -1236 -72 -1314 -78 -81 39 -75
-1377 -78 -1411 -3 -1322 -7 -1404 67 -22 20 -50 56 -61 81 -21 45 -21 52 -24
1210 -2 813 1 1181 9 1217 19 95 100 174 199 194 19 4 598 6 1285 5 1131 -1
1253 -3 1286 -17z"/>
<path d="M5980 7175 c0 -20 7 -26 43 -35 130 -34 192 -68 273 -152 63 -66 108
-152 128 -248 14 -69 28 -94 51 -87 6 2 11 18 13 36 8 84 -51 233 -130 327
-78 93 -248 184 -343 184 -31 0 -35 -3 -35 -25z"/>
<path d="M5966 7045 c-10 -26 9 -42 65 -55 120 -29 235 -155 255 -279 7 -46
26 -66 50 -57 14 5 16 16 11 58 -19 157 -177 323 -328 344 -38 5 -47 3 -53
-11z"/>
<path d="M4647 6983 c-6 -10 -58 -289 -87 -468 -5 -27 -27 -156 -50 -285 -23
-129 -55 -311 -71 -405 -17 -93 -43 -246 -60 -340 -16 -93 -37 -212 -46 -263
-9 -51 -13 -96 -9 -100 4 -4 166 -8 359 -9 268 -2 353 -6 359 -15 4 -7 8 -38
8 -68 0 -124 48 -239 136 -328 134 -134 370 -195 630 -162 299 37 490 188 553
437 61 239 -12 431 -199 526 -34 18 -62 37 -61 42 0 6 13 14 29 18 180 54 311
180 354 342 51 191 18 348 -96 459 -97 94 -224 135 -416 136 -254 0 -421 -60
-575 -207 l-66 -63 -178 0 c-131 0 -181 3 -188 13 -10 12 45 369 59 382 3 4
163 7 354 8 l349 2 5 35 c4 20 9 40 13 46 10 17 48 254 42 264 -7 12 -1141 14
-1148 3z m1350 -874 c92 -57 94 -212 5 -307 -52 -54 -129 -81 -260 -90 l-106
-7 -17 -90 c-53 -289 -57 -265 42 -265 139 0 225 -34 270 -107 19 -31 23 -50
22 -108 -1 -84 -24 -138 -78 -183 -96 -79 -299 -62 -355 30 -42 70 -44 114
-15 291 15 89 28 169 29 177 1 13 -45 15 -351 18 l-353 2 0 23 c0 12 9 74 21
137 11 63 25 144 30 179 5 36 14 70 20 78 9 10 85 13 374 13 l362 0 22 58 c23
64 78 128 133 155 50 25 161 23 205 -4z"/>
<path d="M5961 6906 c-18 -22 -3 -42 40 -55 57 -17 120 -84 140 -146 13 -42
19 -50 39 -50 23 0 25 4 23 37 -5 84 -85 183 -171 213 -55 18 -57 18 -71 1z"/>
<path d="M5901 6734 c-47 -60 -10 -144 64 -144 76 0 116 97 62 148 -35 33 -99
30 -126 -4z"/>
<path d="M3722 4048 c-4 -13 -6 -150 -4 -306 l3 -282 192 2 192 3 3 47 3 47
-123 3 -123 3 0 80 0 80 87 3 c83 3 88 4 94 26 3 13 4 35 2 47 -3 23 -7 24
-96 27 l-93 3 3 67 3 67 115 5 115 5 3 39 c2 24 -2 42 -10 47 -7 5 -91 9 -186
9 -170 0 -173 0 -180 -22z"/>
<path d="M4847 4063 c-4 -3 -7 -141 -7 -306 l0 -300 138 6 c162 6 215 21 272
79 48 50 70 118 70 220 0 90 -20 157 -61 209 -39 50 -88 75 -170 89 -72 11
-232 14 -242 3z m250 -113 c46 -28 66 -73 71 -163 9 -155 -36 -227 -142 -227
l-46 0 0 205 0 205 43 0 c24 0 56 -8 74 -20z"/>
<path d="M5652 4057 c-13 -16 -222 -566 -222 -584 0 -9 19 -13 63 -13 l64 0
24 73 24 72 96 3 c111 3 112 2 138 -85 l17 -58 70 0 c41 0 68 4 67 10 -11 49
-215 580 -225 586 -21 14 -104 10 -116 -4z m95 -238 c34 -110 34 -109 -37
-109 -51 0 -60 3 -60 18 0 14 27 111 45 160 10 29 30 3 52 -69z"/>
<path d="M6044 4056 c-3 -8 -4 -29 -2 -48 3 -32 4 -33 53 -36 104 -5 94 22 97
-260 l3 -247 65 0 65 0 5 250 5 250 70 5 70 5 3 48 3 47 -216 0 c-176 0 -217
-3 -221 -14z"/>
<path d="M6770 4055 c-5 -6 -48 -113 -94 -236 -46 -123 -94 -248 -105 -277
-32 -79 -30 -82 38 -82 67 0 67 -1 96 85 l21 60 103 0 104 0 22 -70 21 -70 67
-3 c47 -2 67 1 67 9 0 27 -205 575 -219 587 -19 15 -108 13 -121 -3z m97 -236
c34 -110 34 -109 -37 -109 -70 0 -71 2 -39 101 11 35 23 70 25 77 9 29 29 2
51 -69z"/>
<path d="M4338 4012 c-69 -25 -73 -32 -49 -81 24 -46 29 -48 72 -25 17 8 47
14 67 12 32 -3 37 -7 40 -29 4 -32 -30 -60 -95 -79 -47 -14 -50 -23 -29 -75
15 -34 16 -35 60 -29 64 9 96 -13 96 -65 0 -70 -55 -94 -140 -62 -61 23 -69
20 -89 -35 -16 -43 -13 -46 66 -69 64 -19 163 -19 206 -1 68 28 116 118 102
192 -4 26 -20 51 -46 76 -38 36 -49 58 -30 58 17 0 51 67 51 101 0 46 -33 97
-75 115 -45 19 -148 17 -207 -4z"/>
</g>
</svg>

		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
